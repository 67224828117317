'use client'
import React, { useEffect, useRef, useState } from 'react';
import Header from '../Header/Header'
import './Hero.css'
import { useTranslation } from 'react-i18next';
// import heroVideo from '../../assets/video/hero.mp4'
import heroVideo from '../../assets/video/hero2.mp4'

export default function Hero() {
    const { t } = useTranslation();
    const textRef = useRef<HTMLHeadingElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const [overlayColor, setOverlayColor] = useState('rgba(0,255,0, 0.4)');

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const textElement = textRef.current;
            if (textElement) {
                const translateX = -scrollPosition / 0.5; // Adjust this value to control the speed
                textElement.style.transform = `translateX(${translateX}px)`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 0.75; // Adjust playback speed if needed
        }
    }, []);

    return (
        <div className="bg-gradient-custom">
            <Header />
            
            <div className="relative h-screen overflow-hidden">
                {/* <video
                    ref={videoRef}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="absolute top-0 left-0 w-full h-full object-cover"
                >
                    <source src={heroVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                 */}
                {/* Color overlay */}
                {/* <div 
                    className="absolute inset-0" 
                    style={{ backgroundColor: overlayColor }}
                ></div> */}
                
                {/* Gradient overlay */}
                {/* <div className="absolute inset-0 bg-gradient-to-br from-red-500 to-black opacity-30"></div> */}
                
                <div className="relative z-10 h-full flex flex-col justify-between">
                    {/* Updated section for the three words as links */}
                    <div className="absolute top-1/2 left-4 right-4 sm:left-40 sm:right-40 transform -translate-y-1/2 px-2 sm:px-8">
                        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
                            <a href="#Iam" className="hero-link text-2xl sm:text-4xl md:text-2xl font-thin text-white opacity-70 uppercase hover:opacity-100 transition-opacity duration-300">{t('hero.innovation')}<span className="text-red-500">.</span></a>
                            <a href="#Iam" className="hero-link text-2xl sm:text-4xl md:text-2xl font-thin text-white opacity-70 uppercase hover:opacity-100 transition-opacity duration-300">{t('hero.development')}<span className="text-red-500">.</span></a>
                            <a href="#Iam" className="hero-link text-2xl sm:text-4xl md:text-2xl font-thin text-white opacity-70 uppercase hover:opacity-100 transition-opacity duration-300">{t('hero.consultation')}<span className="text-red-500">.</span></a>
                        </div>
                    </div>

                    {/* Existing "making ideas happen" text */}
                    <div className="absolute bottom-0 left-0 right-0 p-8 overflow-hidden whitespace-nowrap">
                        <h1
                            ref={textRef}
                            className="hero-title text-[20rem] font-bold text-white leading-none inline-block animate-scroll"
                        >
                            {t('hero.making')}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
    )
}