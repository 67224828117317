'use client'
import React from 'react';
import { motion } from 'framer-motion';
import SecretSauceDigitalDeck from '../../assets/docs/SecretSauceDigitalDeck.pdf';
import { useTranslation } from 'react-i18next';
import './Bio.css';

export default function Bio() {
    const { t } = useTranslation();

    return (
        <section id="Iam" className="bio-section">
            <div className="bio-container">
                <motion.div
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="bio-content"
                >
                    <motion.p 
                        className="bio-tagline"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.2 }}
                    >
                        EXPERTISE MEETS INNOVATION
                    </motion.p>

                    <motion.h2 
                        className="bio-title"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.4 }}
                    >
                        {t('bio.title')}
                        <br/>
                        <span className="bio-subtitle">
                            {t('bio.subtitle')}
                            <span className="accent-dot">.</span>
                        </span>
                    </motion.h2>

                    <motion.div 
                        className="bio-actions"
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.6 }}
                    >
                        <motion.a 
                            href={SecretSauceDigitalDeck} 
                            target="_blank" 
                            rel="noreferrer"
                            className="bio-button primary"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('bio.downloaddeck')}
                            <span className="button-shine"></span>
                        </motion.a>

                        <motion.a 
                            href="#contact" 
                            className="bio-button secondary"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('bio.contactme')}
                            <span className="button-shine"></span>
                        </motion.a>
                    </motion.div>
                </motion.div>
            </div>

            <div className="bio-background">
                <div className="gradient-sphere"></div>
                <div className="grid-pattern"></div>
            </div>
        </section>
    );
}