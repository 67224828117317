import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './AboutMe.css';
import { useTranslation } from 'react-i18next';

const AboutMe: React.FC = () => {
    const { t } = useTranslation();
    const expertiseAreas = [
        {
            title: t('aboutme.expertiseAreas.area1.title'),
            description: t('aboutme.expertiseAreas.area1.description'),
            icon: t('aboutme.expertiseAreas.area1.icon'),
            areaTitle: t('aboutme.expertiseAreas.area1.areaTitle'),
            skills: t('aboutme.expertiseAreas.area1.skills')
        },
        {
            title: t('aboutme.expertiseAreas.area2.title'),
            description: t('aboutme.expertiseAreas.area2.description'),
            icon: t('aboutme.expertiseAreas.area2.icon'),
            areaTitle: t('aboutme.expertiseAreas.area2.areaTitle'),
            skills: t('aboutme.expertiseAreas.area2.skills')
        },
        {
            title: t('aboutme.expertiseAreas.area3.title'),
            description: t('aboutme.expertiseAreas.area3.description'),
            icon: t('aboutme.expertiseAreas.area3.icon'),
            areaTitle: t('aboutme.expertiseAreas.area3.areaTitle'),
            skills: t('aboutme.expertiseAreas.area3.skills')
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2
            }
        }
    };

    const cardVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: { 
            y: 0, 
            opacity: 1,
            transition: {
                type: "spring",
                stiffness: 100,
                damping: 12
            }
        }
    };

    return (
        <section id="about" className="about-me-section">
            <div className="container mx-auto px-6 py-32">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">
                        {t('aboutme.title')}<span className="text-red-500">.</span>
                    </h2>
                    <p className="text-xl text-gray-400 text-center mb-16">{t('aboutme.subtitle')}</p>
                </motion.div>

                <motion.div 
                    className="expertise-grid"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    {expertiseAreas.map((area, index) => (
                        <ExpertiseCard key={index} area={area} variants={cardVariants} />
                    ))}
                </motion.div>
            </div>
        </section>
    );
};

const ExpertiseCard: React.FC<{ area: any; variants: any }> = ({ area, variants }) => {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    return (
        <motion.div 
            ref={ref}
            className="expertise-card"
            variants={variants}
        >
            <div className="expertise-icon-wrapper">
                <span className="expertise-icon">{area.icon}</span>
                <div className="expertise-icon-bg"></div>
            </div>
            
            <h3 className="text-2xl font-semibold text-white mb-4">{area.title}</h3>
            <p className="text-gray-300 mb-4">{area.description}</p>
            
            <div className="expertise-skills">
                <h4 className="text-lg font-semibold text-white mb-3">{area.areaTitle}</h4>
                <motion.ul className="skills-list">
                    {area.skills.split(',').map((skill: string, i: number) => (
                        <motion.li 
                            key={i}
                            className="skill-item"
                            initial={{ opacity: 0, x: -20 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ delay: i * 0.1 }}
                        >
                            {skill.trim()}
                        </motion.li>
                    ))}
                </motion.ul>
            </div>
        </motion.div>
    );
};

export default AboutMe;