import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import './Cs.css';
// import { FaArrowLeft } from 'react-icons/fa';

import cs_sk from '../articles/casestudies/sk.json';
import cs_en from '../articles/casestudies/en.json';

import geisha from '../assets/portfolio/geisha_export.png';
import geisha_thumbnail from '../assets/casestudies/geisha_thumbnail.png';
import agevolt_thumbnail from '../assets/casestudies/agevolt_thumbnail.png';
import antetirt_thumbnail from '../assets/casestudies/antekirt.jpg';
import meta from '../assets/meta_img.png'

interface ImageMap {
  [key: string]: string;
}

const imageMap: ImageMap = {
  geisha: geisha,
  geisha_thumbnail: geisha_thumbnail,
  agevolt_thumbnail: agevolt_thumbnail,
  antetirt_thumbnail: antetirt_thumbnail,
};

const getImage = (imageName: string) => {
  return imageMap[imageName] || '';
};

const categories = ["All", "Web Development", "Blockchain", "AI"];

const Casestudies: React.FC = () => {
  const { t } = useTranslation();
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [hoveredCase, setHoveredCase] = useState<string | null>(null);
  const navigate = useNavigate();
  
  // Add state for case studies data
  const [casestudiesData, setCasestudiesData] = useState(i18n.language === 'sk' ? cs_sk : cs_en);

  // Update case studies when language changes
  useEffect(() => {
    setCasestudiesData(i18n.language === 'sk' ? cs_sk : cs_en);
  }, [i18n.language]);

  const filteredCases = selectedCategory === "All" 
    ? casestudiesData 
    : casestudiesData.filter(cs => cs.industry === selectedCategory);

  return (
    <div className="casestudies-container min-h-screen">
      <Helmet>
        <title>{t('casestudies.title')} | {t('helmet.title')}</title>
        <meta name="description" content={t('casestudies.description')} />
        <meta name="author" content="Ondrej Sarnecky" />
        <meta name="robots" content="index, follow" />
        <meta name="image" content={meta} />
      </Helmet>
      <Header />
      
      <main className="container mx-auto">
        <div className="px-4 pt-32 pb-16">
        {/* <motion.button
          onClick={() => navigate(`/${i18n.language}`)}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="back-button mb-8"
        >
          <FaArrowLeft className="mr-2" />
          {t('common.back')}
        </motion.button> */}
        <motion.h1 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-5xl font-bold text-white mb-8"
        >
          {t('casestudies.title')}<span className="text-red-500">.</span>
        </motion.h1>

        <motion.div 
          className="categories-filter mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
            >
              {category}
            </button>
          ))}
        </motion.div>

        <div className="cases-grid">
          <AnimatePresence mode='wait'>
            {filteredCases.map((casestudy) => (
              <Link 
                to={`/${i18n.language}/casestudies/${casestudy.id}`}
                key={casestudy.id}
                className="case-card-link"
              >
                <motion.div
                  layout
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.9 }}
                  whileHover={{ y: -10 }}
                  className="case-card"
                  onHoverStart={() => setHoveredCase(casestudy.id)}
                  onHoverEnd={() => setHoveredCase(null)}
                >
                  <div className="case-image-wrapper">
                    <img 
                      src={getImage(casestudy.thumbnail)} 
                      alt={casestudy.title} 
                      className="case-image" 
                    />
                    <motion.div 
                      className="case-overlay"
                      animate={{ 
                        opacity: hoveredCase === casestudy.id ? 1 : 0 
                      }}
                    >
                      <div className="tech-tags">
                        {casestudy.technologies.map((tech) => (
                          <span key={tech} className="tech-tag">{tech}</span>
                        ))}
                      </div>
                    </motion.div>
                  </div>

                  <div className="case-content">
                    <h2 className="text-2xl font-bold text-white mb-2">
                      {casestudy.title}
                    </h2>
                    <p className="text-gray-400 mb-4">{casestudy.subtitle}</p>
                    <div className="flex justify-between items-center">
                      <span className="text-sm text-gray-500">{casestudy.date}</span>
                      <span className="underline text-white">
                        {t('casestudies.readmore')}
                      </span>
                    </div>
                  </div>
                </motion.div>
              </Link>
            ))}
          </AnimatePresence>
        </div>
        </div>
      </main>
    </div>
  );
};

export default Casestudies;