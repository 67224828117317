import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaArrowLeft, FaGithub, FaExternalLinkAlt } from 'react-icons/fa';
import Header from '../components/Header/Header';
import Contact from '../components/ContactSection/ContactSection';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import './CsArticle.css';
import casestudiesData from '../articles/casestudies/casestudies.json';
import cs_sk from '../articles/casestudies/sk.json';
import cs_en from '../articles/casestudies/en.json';
import geisha from '../assets/portfolio/geisha_export.png';

interface CaseStudy {
  id: string;
  title: string;
  subtitle: string;
  content: {
    overview: string;
    challenge: string;
    solution: string;
    results: string[];
  };
  date: string;
  duration: string;
  client: string;
  industry: string;
  technologies: string[];
  mainImage: string;
  video?: string | null;
  links?: {
    github?: string;
    live?: string;
  };
}

interface ImageMap {
  [key: string]: string;
}

const imageMap: ImageMap = {
  geisha: geisha
};

const getImage = (imageName: string) => {
  return imageMap[imageName] || '';
};

// Mock data - replace with your actual case studies
const casestudies: CaseStudy[] = casestudiesData;

const CsArticle: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [casestudiesData, setCasestudiesData] = useState(i18n.language === 'sk' ? cs_sk : cs_en);
  
  const study = casestudiesData.find(cs => cs.id === String(id));

  useEffect(() => {
    if (!study) {
      navigate(`/${i18n.language}/casestudies`);
    }
  }, [study, navigate]);

  useEffect(() => {
    setCasestudiesData(i18n.language === 'sk' ? cs_sk : cs_en);
  }, [i18n.language]);

  if (!study) return null;

  return (
    <div className="case-study-container min-h-screen">
      <Helmet>
        <title>{`${study.title} | ${t('casestudies.title')} | ${t('helmet.title')}`}</title>
        <meta name="description" content={study.content.overview} />
      </Helmet>
      <Header />

      <main className="container mx-auto">
        <div className="px-4 pt-24 pb-16">
        {/* Back Button */}
        <motion.button
          onClick={() => navigate(`/${i18n.language}/casestudies`)}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="back-button mb-8"
        >
          <FaArrowLeft className="mr-2" />
          {t('common.backToCases')}
        </motion.button>

        {/* Hero Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="hero-section mb-16"
        >
          <h1 className="text-5xl font-bold text-white mb-4">{study.title}</h1>
          <p className="text-xl text-gray-400 mb-8">{study.subtitle}</p>
          
          <div className="project-links">
            {study.links?.github && (
              <a href={study.links.github} target="_blank" rel="noopener noreferrer" className="project-link">
                <FaGithub /> View Code
              </a>
            )}
            {study.links?.live && (
              <a href={study.links.live} target="_blank" rel="noopener noreferrer" className="project-link">
                <FaExternalLinkAlt /> Live Site
              </a>
            )}
          </div>
        </motion.div>

        {/* Project Details Grid */}
        <div className="project-details-grid mb-16">
          <DetailCard title="Client" content={study.client} />
          <DetailCard title="Industry" content={study.industry} />
          <DetailCard title="Duration" content={study.duration} />
          <DetailCard title="Year" content={study.date} />
        </div>

        {/* Main Image */}
        {!study.video && (
          <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="main-image-container mb-16"
        >
            <img src={getImage(study.mainImage)} alt={study.title} className="main-image" />
          </motion.div>
        )}

        {/* Video */}
        {study.video && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="video-container mb-16"
          >
            <iframe src={study.video} title={study.title} className="video" allowFullScreen width="100%" height="500" />
          </motion.div>
        )}

        {/* Content Sections */}
        <div className="content-grid">
          <ContentSection title="Overview" content={study.content.overview} />
          <ContentSection title="Challenge" content={study.content.challenge} />
          <ContentSection title="Solution" content={study.content.solution} />
          
          <div className="results-section">
            <h2 className="text-2xl font-bold text-white mb-6">Key Results</h2>
            <ul className="results-list">
              {study.content.results.map((result, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  {result}
                </motion.li>
              ))}
            </ul>
          </div>
        </div>

        {/* Technologies */}
        <div className="technologies-section mt-16">
          <h2 className="text-2xl font-bold text-white mb-6">Technologies Used</h2>
          <div className="tech-tags-cs-detail">
            {study.technologies.map((tech, index) => (
              <motion.span
                key={tech}
                className="tech-tag-cs-detail"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: index * 0.1 }}
              >
                {tech}
              </motion.span>
            ))}
          </div>
        </div>

        {/* Image Gallery */}
        {/* {study.gallery.length > 0 && (
          <div className="gallery-section mt-16">
            <h2 className="text-2xl font-bold text-white mb-6">Project Gallery</h2>
            <div className="gallery-grid">
              {study.gallery.map((image, index) => (
                <motion.div
                  key={index}
                  className="gallery-item"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                >
                  <img src={image} alt={`${study.title} gallery ${index + 1}`} />
                </motion.div>
              ))}
            </div>
          </div>
        )} */}
        </div>
      </main>
      <Contact />
    </div>
  );
};

// Helper Components
const DetailCard: React.FC<{ title: string; content: string }> = ({ title, content }) => (
  <div className="detail-card">
    <h3 className="text-gray-400">{title}</h3>
    <p className="text-white font-semibold">{content}</p>
  </div>
);

const ContentSection: React.FC<{ title: string; content: string }> = ({ title, content }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="content-section"
  >
    <h2 className="text-2xl font-bold text-white mb-6">{title}</h2>
    <p className="text-gray-300 leading-relaxed">{content}</p>
  </motion.div>
);

export default CsArticle;