import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import './ComingSoon.css';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const ComingSoon: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    console.log('Component mounted');

    if (containerRef.current) {
      console.log('Container ref is available');

      // Create and animate dots
      const dotCount = 50;
      const dots: HTMLDivElement[] = [];

      for (let i = 0; i < dotCount; i++) {
        const dot = document.createElement('div');
        dot.className = 'dot';
        containerRef.current.appendChild(dot);
        dots.push(dot);

        gsap.set(dot, {
          x: Math.random() * window.innerWidth,
          y: Math.random() * window.innerHeight,
          backgroundColor: `rgba(${Math.random() * 255}, ${Math.random() * 255}, 255, 0.7)`,
        });

        gsap.to(dot, {
          duration: Math.random() * 3 + 2,
          x: '+=50',
          y: '+=50',
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
        });
      }

      return () => {
        console.log('Component will unmount');
        // Clean up animations
        dots.forEach(dot => {
          gsap.killTweensOf(dot);
          dot.remove();
        });
      };
    }
  }, []);

  return (
    <div ref={containerRef} className="coming-soon-container">
      <div className="coming-soon-content">
        <h1 className="coming-soon-title">{t('comingsoon.title')}</h1>
        <p className="coming-soon-subtitle">{t('comingsoon.subtitle')}</p>
        <Link to={`/${i18n.language}`} className="w-full sm:w-auto px-4 sm:px-8 py-3 border-2 hover:bg-white text-white hover:text-black font-semibold full hover:bg-opacity-90 transition duration-300">
          {t('comingsoon.button')}
        </Link>
      </div>
    </div>
  );
};

export default ComingSoon;