import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './WhyWorkWithMe.css';
import { useTranslation } from 'react-i18next';
import OSCV from '../../assets/docs/Ondrej Sarnecky SSD CV.pdf';

const WhyWorkWithMe: React.FC = () => {
    const { t } = useTranslation();
    
    const reasons = [
        {
            title: t('whyWorkWithMe.reasons.businessTech.title'),
            description: t('whyWorkWithMe.reasons.businessTech.description'),
            icon: t('whyWorkWithMe.reasons.businessTech.icon')
        },
        {
            title: t('whyWorkWithMe.reasons.agility.title'),
            description: t('whyWorkWithMe.reasons.agility.description'),
            icon: t('whyWorkWithMe.reasons.agility.icon')
        },
        {
            title: t('whyWorkWithMe.reasons.communication.title'),
            description: t('whyWorkWithMe.reasons.communication.description'),
            icon: t('whyWorkWithMe.reasons.communication.icon')
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: { staggerChildren: 0.2 }
        }
    };

    return (
        <section className="why-work-with-me-section">
            <div className="container mx-auto px-6 py-32">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                >
                    <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">
                        {t('whyWorkWithMe.title')}<span className="text-red-500">.</span>
                    </h2>
                    <p className="text-xl text-gray-400 text-center mb-16">
                        {t('whyWorkWithMe.subtitle')}
                    </p>
                </motion.div>

                <motion.div 
                    className="reasons-grid"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    {reasons.map((reason, index) => (
                        <ReasonCard key={index} reason={reason} index={index} />
                    ))}
                </motion.div>

                <motion.div 
                    className="cta-container"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.6 }}
                >
                    <a href={OSCV} target="_blank" rel="noreferrer">
                        <motion.button 
                            className='cta-button primary'
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('whyWorkWithMe.downloadcv')}
                        </motion.button>
                    </a>
                    <a href="mailto:ondrej.sarnecky@0x1.sk">
                        <motion.button 
                            className='cta-button secondary'
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('whyWorkWithMe.contact')}
                        </motion.button>
                    </a>
                </motion.div>
            </div>
        </section>
    );
};

const ReasonCard: React.FC<{ reason: any; index: number }> = ({ reason, index }) => {
    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    });

    return (
        <motion.div 
            ref={ref}
            className="reason-card"
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.5, delay: index * 0.2 }}
        >
            <div className="reason-icon-wrapper">
                <span className="reason-icon">{reason.icon}</span>
                <div className="icon-background"></div>
            </div>
            <h3 className="text-2xl font-semibold text-white mb-4">{reason.title}</h3>
            <p className="text-gray-300">{reason.description}</p>
        </motion.div>
    );
};

export default WhyWorkWithMe;