import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './ProjectShowcase.css';
import { useNavigate } from 'react-router-dom';

import i18n from '../../i18n';

import antetirt from '../../assets/casestudies/antekirt.jpg'
import bianalytiq from '../../assets/portfolio/bianalytiq.png'
import anyrent from '../../assets/portfolio/Anyrent.png'
import droplove from '../../assets/portfolio/droplove_export.png'
import xau from '../../assets/portfolio/xaufinance_2_export.png'
import geisha from '../../assets/portfolio/geisha_export.png'
import bthr from '../../assets/portfolio/bthr.png'
import { useTranslation } from 'react-i18next';

interface Project {
    title: string;
    description: string;
    image?: string;
    video?: string;
    technologies: string[];
    link: string;
    responsibilities: string[];
}

const ProjectShowcase: React.FC = () => {
    const { t } = useTranslation();
    const [activeProject, setActiveProject] = useState(0);
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    const projects: Project[] = [
        {
            title: "Antekirt - Authentic AI Art Generation with Copyright Protection",
            description: "Product design and full-stack development. MVP stage and product validation in progress.",
            video: "https://www.youtube.com/embed/CWiT2ERMt64?si=lsDOglM2ehFUmcMO",
            technologies: ["Stable Diffusion", "LoRA", "Python", "Express", "React", "Node.js", "PostgreSQL", "AWS", "Docker"],
            link: "https://antekirt.com/",
            responsibilities: ["Product design", "MVP development", "Market fit validation"]
        },
        {
            title: "Anyrent - Online půjčovna sportovního vybavení",
            description: "The largest online rental service for bikes, skis and sports equipment with delivery.",
            image: anyrent,
            technologies: ["Vue.js", "Node.js", "PostgreSQL", "Docker", "Strapi", "GraphQL"],
            link: "https://anyrent.cz/",
            responsibilities: ["Helping with project finalization", "Frontend development", "Backend development", "CMS Integration"]
        },
        {
            title: "Bianalytiq - AI Data Intelligence Platform",
            description: "Product design and full-stack development. MVP stage and product validation in progress.",
            image: bianalytiq,
            technologies: ["LLama", "RAG", "Python", "Express", "React", "Node.js", "PostgreSQL", "AWS", "Docker"],
            link: "https://bianalytiq.com/",
            responsibilities: ["Product design", "MVP development", "Market fit validation"]
        },
        {
            title: "Droplove.xyz - NFT Creator Platform",
            description: "Product design and full-stack development. Achieved the #1 Product of the day badge on Producthunt.com while onboarding 500+ user in one day.",
            image: droplove,
            technologies: ["Solidity", "Ethereum", "Node.js", "MongoDB", "React", "Docker"],
            link: "https://droplove.xyz",
            responsibilities: ["Product design", "Full-stack development", "User onboarding"]
        },
        {
            title: "Agevolt EV Charging Tokenization",
            description: "Product design and full-stack development. Solution for creating a 'White Label Loyalty Tokens' for the owners of EV Charging Points (EVCP). Won first prize 30000€ in EU project competition.",
            video: "https://www.youtube.com/embed/gtGTXfg3n6c?si=fXiYqGWma7QzeGjt",
            technologies: ["Hyperledger Besu", "Solidity", "Nodejs", "AWS", "React", "Docker"],
            link: "https://www.blockstart.eu/portfolio/agevolt/",
            responsibilities: ["Product design", "Full-stack development", "Investors presentation"]
        },
        {
            title: "Unimpressed Geisha - NFTs & Farming tool",
            description: "Full-stack development - platform + NFTs. Collection of 777 unique digital art collectables created by the female artist Zuzu Galova @inhideandseek. Trade volume in one day 14ETH.",
            image: geisha,
            technologies: ["Solidity", "Ethereum", "Node.js", "MongoDB", "React", "Docker"],
            link: "https://www.unimpressedgeisha.com/",
            responsibilities: ["Business model innovation", "Full-stack development", "NFT implementation"]
        },
        {
            title: "XAU.finance - Tokenized Gold",
            description: "Smart contract design and development. The first DAG DeFi platform of its kind with market cap $10 000 000+",
            image: xau,
            technologies: ["Solidity", "Ethereum", "Truffle", "Hardhat", "Docker"],
            link: "https://xau.finance/",
            responsibilities: ["Smart contract design", "Smart contract development", "DeFi platform implementation"]
        },
        {
            title: "Bethereum - p2p Sports Betting",
            description: "Orchestrated the development and execution of Bethereum ICO, successfully fundraising over $4 million",
            image: bthr,
            technologies: ["Solidity", "Ethereum", "Truffle", "Hardhat", "Docker"],
            link: "https://cryptototem.com/bethereum-bthr-ico/",
            responsibilities: ["Product design", "ICO development", "ICO execution"]
        }
    ];

    return (
        <section id="portfolio" className="project-showcase-section">
            <div className="showcase-container">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.6 }}
                    className="showcase-header"
                >
                    <h2 className="text-4xl md:text-5xl font-bold text-white text-center mb-2">
                        {t('selectedworks.title')}<span className="text-red-500">.</span>
                    </h2>
                    <p className="text-xl text-gray-400 text-center mb-16">{t('selectedworks.subtitle')}</p>
                </motion.div>

                <div className="preview-section">
                    <AnimatePresence mode='wait'>
                        <motion.div
                            key={activeProject}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.5 }}
                            className="preview-content"
                        >
                            {projects[activeProject].video ? (
                                <div className="video-wrapper">
                                    <iframe 
                                        src={projects[activeProject].video} 
                                        title="YouTube video player" 
                                        frameBorder="0" 
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                        allowFullScreen
                                    />
                                </div>
                            ) : (
                                <motion.img
                                    src={projects[activeProject].image}
                                    alt={projects[activeProject].title}
                                    className="preview-image"
                                    layoutId={`project-image-${activeProject}`}
                                />
                            )}
                        </motion.div>
                    </AnimatePresence>
                </div>

                <div className="projects-scroll-container" ref={ref}>
                    <div className="projects-track">
                        {projects.map((project, index) => (
                            <ProjectCard
                                key={index}
                                project={project}
                                index={index}
                                isActive={index === activeProject}
                                onSelect={() => setActiveProject(index)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <motion.div 
                    className="cta-container"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ delay: 0.6 }}
                >
                    <a href={`/${i18n.language}/casestudies`} rel="noreferrer">
                        <motion.button 
                            className='cta-button primary'
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            {t('selectedworks.viewcasestudies')}
                        </motion.button>
                    </a>
                </motion.div>
        </section>
    );
};

const ProjectCard: React.FC<{
    project: Project;
    index: number;
    isActive: boolean;
    onSelect: () => void;
}> = ({ project, index, isActive, onSelect }) => {
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    return (
        <motion.div
            ref={ref}
            className={`project-card ${isActive ? 'active' : ''}`}
            initial={{ opacity: 0, x: -50 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            onClick={onSelect}
        >
            <h3 className="text-2xl font-semibold text-white mb-3">{project.title}</h3>
            <p className="text-gray-300 mb-4">{project.description}</p>
            
            <div className="tech-stack">
                {project.technologies.map((tech, i) => (
                    <motion.span
                        key={i}
                        className="tech-tag"
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={inView ? { opacity: 1, scale: 1 } : {}}
                        transition={{ delay: i * 0.1 }}
                    >
                        {tech}
                    </motion.span>
                ))}
            </div>

            <div className="responsibilities">
                <h4 className="text-lg font-semibold text-white mb-2">My contribution:</h4>
                <ul className="responsibility-list">
                    {project.responsibilities.map((resp, i) => (
                        <motion.li
                            key={i}
                            initial={{ opacity: 0, x: -20 }}
                            animate={inView ? { opacity: 1, x: 0 } : {}}
                            transition={{ delay: i * 0.1 }}
                        >
                            {resp}
                        </motion.li>
                    ))}
                </ul>
            </div>

            <motion.a
                href={project.link}
                target="_blank"
                rel="noreferrer"
                className="view-project-btn"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                View Project
            </motion.a>
        </motion.div>
    );
};

export default ProjectShowcase;