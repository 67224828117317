import React from 'react';
import { Helmet } from 'react-helmet-async';
import Hero from '../components/Hero/Hero';
import Bio from '../components/Bio/Bio';
import Experience from '../components/Experience/Experience';
import AboutMe from '../components/AboutMe/AboutMe';
import ProjectShowcase from '../components/ProjectShowcase/ProjectShowcase';
import PhotoGallery from '../components/PhotoGallery/PhotoGallery';
import WhyWorkWithMe from '../components/WhyWorkWithMe/WhyWorkWithMe';
import ContactSection from '../components/ContactSection/ContactSection';
import { useTranslation } from 'react-i18next';

import meta from '../assets/meta_img.png'

export default function Home() {
  const { t } = useTranslation();
  return (
    <div>
      <Helmet>
        <title>{t('helmet.title')}</title>
        <meta name="description" content={t('helmet.description')} />
        <meta name="keywords" content={t('helmet.keywords')} />
        <meta name="description" content={t('casestudies.description')} />
        <meta name="author" content="Ondrej Sarnecky" />
        <meta name="robots" content="index, follow" />
        <meta name="image" content={meta} />
      </Helmet>
      <Hero />
      <Bio />
      <Experience />
      <AboutMe />
      <ProjectShowcase />
      <PhotoGallery />
      <WhyWorkWithMe />
      <ContactSection />
    </div>
  )
}