import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import Blog from './pages/Blog';
import ComingSoon from './pages/ComingSoon';
import CustomCursor from './components/CustomCursor/CustomCursor';
import Background from './components/Background/Background';
import Casestudies from './pages/Cs';
import CsArticle from './pages/CsArticle';
import BlogArticle from './pages/BlogArticle';
// import GradientOverlay from './components/GradientOverlay/GradientOverlay';

const LanguageWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const lang = pathname.startsWith('/sk') ? 'sk' : 'en';

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return <>{children}</>;
};

const App: React.FC = () => {
  const { t } = useTranslation();

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "ConsultationService",
    "name": "Secret Sauce Digital by Ondrej Sarnecky",
    "description": "Ondrej Sarnecky - Your secret sauce for tech innovation. I'm helping companies grow in their revenue by making them cool.",
    "url": "https://secretsauce.digital",
    "founder": {
      "@type": "Person",
      "name": "Ondrej Sarnecky"
    },
    "areaServed": "Worldwide",
    "serviceType": ["Tech Innovation", "Full-stack Development", "AI Development", "Blockchain Solutions"]
  };

  return (
    <HelmetProvider>
      <Router>
        <LanguageWrapper >
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        </LanguageWrapper>
        <Background />
        <Routes>
          <Route path="/" element={<Navigate to="/en" replace />} />
          <Route path="/:lang" element={<Home />} />
          <Route path="/en/coming-soon" element={<ComingSoon />} />
          <Route path="/:lang/coming-soon" element={<ComingSoon />} />
          <Route path="/:lang/casestudies" element={<Casestudies />} />
          <Route path="/:lang/casestudies/:id" element={<CsArticle />} />
          {/* <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<BlogArticle />} /> */}
        </Routes>
        <CustomCursor />
        {/* <GradientOverlay /> */}
      </Router>
    </HelmetProvider>
  );
}

export default App;