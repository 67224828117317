import React from 'react';
import './ContactSection.css';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from 'react-i18next';

const ContactSection: React.FC = () => {
    const { t } = useTranslation();
    return (
        <section id="contact" className="contact-section">
            <div className="container">
                <h2>Let's Chat<span className="text-red-500">.</span></h2>
                <p className="subheading">
                    {t('contact.subtitle')} {' '}
                    <a href="mailto:ondrej.sarnecky@0x1.sk" className="email-link">
                        {t('contact.email')} ondrej.sarnecky@0x1.sk
                    </a> {' '}
                    {t('contact.email2')}
                </p>
                <p className="alternative">
                    {t('contact.alternative')}
                </p>
                <div className="social-links">
                    <a href="https://github.com/OndroS" target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                    </a>
                    <a href="https://www.linkedin.com/in/ondrej-sarnecky/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                    <a href="https://twitter.com/ondrej_sarnecky" target="_blank" rel="noopener noreferrer">
                        <FaXTwitter />
                    </a>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;